import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>
      That page is missing, but good news: your mission to make a difference doesn’t stop here.
      Let’s get you back to making the world a better place.
    </p>
  </Layout>
)

export default NotFoundPage
